import React, { useEffect } from 'react';
import {
  Edit,
  SimpleForm,
  TextField,
  ReferenceInput,
  ReferenceField,
  TextInput,
  DateInput,
  SelectInput,
  Toolbar,
  SaveButton,
  DeleteWithConfirmButton,
  useGetList,
  useRecordContext,
  required,
  AutocompleteInput,
  RadioButtonGroupInput,
  Labeled,
} from 'react-admin';
import BackButton from '../Components/BackButton';
import { CircularProgress, Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { withAuth } from '../Config/ApplicationDataProvider';
import axios from 'axios';
import { API_URL } from '../Config/ReactAppConfig';
import { withLogging } from '../System/LoggingUtils';

const EndDateMessage = () => (
  <span
    style={{ color: 'red', backgroundColor: 'white', textShadow: '4px 4px 8px rgba(0, 0, 0, 0.8)' }}
  >
    (This date can be modified/extended even if the class status is showing "Complete" or
    "Cancelled")
  </span>
);

const StartTimeMessage = () => (
  <span
    style={{ color: 'red', backgroundColor: 'white', textShadow: '4px 4px 8px rgba(0, 0, 0, 0.8)' }}
  >
    (All stacks in this class will automatically start everyday at this time if the class Auto Start
    is enabled)
  </span>
);

const EndTimeMessage = () => (
  <span
    style={{ color: 'red', backgroundColor: 'white', textShadow: '4px 4px 8px rgba(0, 0, 0, 0.8)' }}
  >
    (All stacks in this class will automatically pause everyday at this time. The class Auto Pause
    feature is enabled by default)
  </span>
);

const CustomToolbar = (props) => {
  const record = useRecordContext();
  const { data, isLoading, error } = useGetList('tveclassappusers', {
    filter: { tveclassid: record.id },
  });
  const deleteConfirmationMessageTitle = `Delete class: ` + record.name;
  if (isLoading) {
    return <CircularProgress />;
  }
  if (error) {
    return <p>{error}</p>;
  }
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <SaveButton />
      <DeleteWithConfirmButton
        disabled={data.length > 0}
        confirmTitle={deleteConfirmationMessageTitle}
        confirmContent="Are you sure you want to delete this class?"
        variant="contained"
        sx={{
          color: 'white',
          backgroundColor: 'red',
          padding: '6px 11px',
          '&:hover': {
            backgroundColor: '#0066CC',
          },
        }}
      />
    </Toolbar>
  );
};

const validateNameUnique = async (enteredName, currentClassId) => {
  const response = await axios(withAuth({ url: `${API_URL}/tveclass/${enteredName}` }));
  return response.data.find((it) => {
    return (
      it.name.trim().toLowerCase() === enteredName.trim().toLowerCase() &&
      it.tveClassId !== currentClassId
    );
  })
    ? 'Class name already exists'
    : undefined;
};

const validateClassEdit = async (values) => {
  const errors = {};
  const invalidChars = ['/', '\\', '%', '?', '|', '[', ']', '#'];
  if (!values.name) {
    errors.name = 'Class Name is required';
  } else if (invalidChars.some((char) => values.name.includes(char))) {
    errors.name = 'Class Name contains an invalid character: /, \\, %, ?, |, [, ], or #';
  } else if (values.name?.length > 255) {
    errors.name = 'Class Name must be not be > 255 characters';
  } else {
    const nameError = await validateNameUnique(values.name, values.tveClassId);
    if (nameError) {
      errors.name = nameError;
    }
  }
  if (!values.classType) {
    errors.classType = 'Class Type is required';
  }
  if (!values.tveTrainingLocation) {
    errors.tveTrainingLocation = 'Training Location is required';
  }
  if (!values.tveTimeZone) {
    errors.tveTimeZone = 'Training Location Time Zone is required';
  }
  if (!values.startDate) {
    errors.startDate = 'Start Date is required';
  }
  if (!values.endDate) {
    errors.endDate = 'End Date is required';
  }
  if (values.endDate <= values.startDate) {
    errors.endDate = 'End Date must be after Start Date';
  }
  if (!values.startTime) {
    errors.startTime = 'Daily Start Time is required';
  }
  if (!values.endTime) {
    errors.endTime = 'Daily End Time is required';
  }
  if (values.endTime <= values.startTime) {
    errors.endTime = 'End Time must be after Start Time';
  } else if (values.endTime > values.startTime) {
    const endTime = values.endTime.split(':');
    const endHourMinute = parseInt(endTime[0] + endTime[1]);
    const startTime = values.startTime.split(':');
    const startHourMinute = parseInt(startTime[0] + startTime[1]);
    const diff = endHourMinute - startHourMinute;
    if (diff > 800)
      errors.endTime = 'Difference between End Time and Start Time cannot be greater than 8 hours';
  }
  return errors;
};

const TimeZoneInput = () => {
  const formContext = useFormContext();

  const { data: locationdata } = useGetList('traininglocations');

  useEffect(() => {
    const values = formContext.getValues();
    const { dirtyFields } = formContext.formState;

    // if the user set the location, but did not set the time zone (to intentionally override the default)
    //  then set the time zone to the default
    if (
      Object.keys(dirtyFields).includes('tveTrainingLocation') &&
      !Object.keys(dirtyFields).includes('tveTimeZone')
    ) {
      var selectedlocation = locationdata.find((loc) => {
        return loc.id === values.tveTrainingLocation.id;
      });
      formContext.setValue('tveTimeZone.id', selectedlocation.tveTimeZone.id);
    }
  });

  return (
    <ReferenceInput
      source="tveTimeZone.id"
      reference="timezones"
      sort={{ field: 'sort', order: 'ASC' }}
    >
      <SelectInput
        optionText="official"
        label="Training Location Time Zone"
        sx={{ width: 350 }}
        validate={required()}
      />
    </ReferenceInput>
  );
};

const TveClassEditTitle = () => {
  const record = useRecordContext();
  return <>{record ? `Edit Class: ${record.id} - ${record.name}` : 'Edit Class'}</>;
};

const TveClassEdit = () => {
  return (
    <>
      <BackButton label="Return to Class List" to="/tveclasses" />
      <Edit title={<TveClassEditTitle />} mutationMode="pessimistic">
        <SimpleForm
          toolbar={<CustomToolbar />}
          mode="onBlur"
          reValidateMode="onBlur"
          validate={validateClassEdit}
        >
          <TextInput source="name" label="Class Name" sx={{ width: 700 }} />
          <Labeled label="Track">
            <TextField sx={{ pb: 1.5 }} source="track" />
          </Labeled>
          <RadioButtonGroupInput
            source="classType"
            label="Class Type"
            validate={required()}
            choices={[
              {
                id: 'I',
                name:
                  "Instructing: Only use for scheduled Sailors' training " + '\u00A0 '.repeat(90),
              },
              {
                id: 'N',
                name: 'Non-Instructing: Not for classroom instruction. Use for Practice, Test, Demo, Review and Explore C4I TVE features',
              },
            ]}
          />
          <ReferenceInput
            source="tveTrainingLocation.id"
            reference="traininglocations"
            sort={{ field: 'sort', order: 'ASC' }}
          >
            <SelectInput
              label="Training Location"
              optionText={(choice) => `${choice.displayName}`}
              sx={{ width: 350 }}
              validate={required()}
            />
          </ReferenceInput>
          <TimeZoneInput />
          <DateInput sx={{ width: 400 }} source="startDate" />
          <div style={{ display: 'flex' }}>
            <DateInput sx={{ width: 400 }} source="endDate" />
            <div style={{ marginTop: '20px', paddingLeft: '10px' }}>
              <EndDateMessage />
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <TextInput
              sx={{ width: 200 }}
              label="Daily Start Time (type or select)"
              source="startTime"
              type="time"
            />
            <div style={{ marginTop: '20px', paddingLeft: '10px' }}>
              <StartTimeMessage />
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <TextInput
              sx={{ width: 200 }}
              label="Daily End Time (type or select)"
              source="endTime"
              type="time"
            />
            <div style={{ marginTop: '20px', paddingLeft: '10px' }}>
              <EndTimeMessage />
            </div>
          </div>
          <ReferenceInput
            source="course.courseId"
            reference="courses"
            sort={{ field: 'id', order: 'ASC' }}
          >
            <AutocompleteInput
              label="Course"
              debounce={500}
              optionText={(choice) => `${choice.name} - ${choice.description}`} sx={{ width: 800 }}
              validate={required()}
            />
          </ReferenceInput>
          <ReferenceField source="tveClassStatus.lookupCodeId" reference="classstatuses">
            <Labeled label="Status">
              <TextField label="Status" source="name" />
            </Labeled>
          </ReferenceField>
        </SimpleForm>
      </Edit>
    </>
  );
};

export default withLogging(TveClassEdit);
