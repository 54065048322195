import * as React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import BackButton from '../Components/BackButton';
import { Title, CreateButton, Button } from 'react-admin';
import TveTitle from '../Components/TveTitle';

const FaqCreateButton = () => <CreateButton resource="faq" label="faqs" variant="contained" />;

const TermCreateButton = () => <CreateButton resource="term" label="terms" variant="contained" />;

const ResourceLink = ({ label, url }) => (
  <span style={{ fontSize: '14px' }}>
    <br />
    {label}&nbsp;
    <Button
      sx={{
        fontFamily: 'Segoe UI',
        fontSize: '14px',
        pb: 0,
        pt: 0,
        pr: 0,
        textTransform: 'none',
        justifyContent: 'center',
      }}
      variant="contained"
      onClick={() => window.open(url, '_blank')}
    >
      here
    </Button>
  </span>
);

const ContactSection = () => (
  <>
    <Card>
      <CardHeader title="Contact Us" />
      <hr />
      <CardContent>
        <span style={{ fontSize: '14px' }}>
          To contact the C4I TVE Support team, please e-mail c4itvesupport@deloitte.com{' '}
        </span>
        <br /> <br />
        <span style={{ fontSize: '14px' }}>
          To leave a voicemail for the C4I TVE Support team, please call +1 (833) 294-2715 (Note:
          toll number is +1 (615) 738-2054).
        </span>
      </CardContent>
    </Card>
  </>
);

const DocumentationSection = () => (
  <>
    <Card>
      <CardHeader title="Documentation" />
      <hr />
      <CardContent>
        <span style={{ fontSize: '14px' }}>
          <ResourceLink
            label="Access TVE Documentation"
            url="https://c4itve.navy.mil/instructor-content/resources/Reference%20Documents/TVE%20Documentation/"
          />
        </span>
      </CardContent>
    </Card>
  </>
);

const Footer = () => {
  return (
    <>
      <Title title={<TveTitle pageName="Help and Support" />} />
      <BackButton label="Return to Class List" to="/tveclasses" />
      <hr />
      <Card>
        <CardHeader title="FAQs" />
        <hr />
        <CardContent>{<FaqCreateButton />}</CardContent>
      </Card>
      <hr />
      <Card>
        <CardHeader title="Glossary" />
        <hr />
        <CardContent>{<TermCreateButton />}</CardContent>
      </Card>
      <hr />
      <ContactSection />
      <hr />
      <Card>
        <CardHeader title="Feedback" />
        <hr />
        <CardContent>
          <CreateButton label="Provide TVE Feedback" to={`/feedback/create`} variant="contained" />
        </CardContent>
      </Card>
      <hr />
      <DocumentationSection />
    </>
  );
};
export default Footer;
