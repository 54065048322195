export const Data = [
    {
        question: 'The adm.internal and adm.internal2 logon accounts in a TVE stack have both been locked out so that the stack is now inaccessible.  What do I do now?',
        answer: 'There is an automated process in place in every stack that will unlock these accounts each time the stack is started up.  In the TVE Web App, select the stack in question and click the “Pause TVE” link in the upper right area.  Wait until the stack reaches a “Paused” state.  Then select the stack again and click “Start TVE” in the upper right area.'
    },

    {
        question: 'A TVE Stack is reverting to a “Paused” state shortly after I click the “Start TVE” link in the upper right area in the TVE App.  How do I get the stack into a “Running” state?',
        answer: 'Contact the TVE Support team at c4itvesupport@deloitte.com right away.  This is an indicator of a resource capacity issue in the AWS GovCloud environment.  It will require manual intervention directly with AWS in order to resolve the issue and get the stack into a Running state.  This typically takes less than 30 minutes to resolve.'
    },

    {
        question: 'A TVE stack has just transitioned to “Running” status, but I’m finding some of the servers or services are not fully started or functional.  What is causing this?',
        answer: 'This can happen depending on a number of factors, normally related to how long the stack has been up and running, and whether or not the stack has been through its first pause/restart cycle after its initial creation.  If this occurred right after you initially created the stack and it hadn’t been through its first pause/restart cycle, it’s somewhat normal.  Even though we’ve resolved many of the initial start-up issues when a stack is created, there is often a “lag” in timing related to all of the virtual machines and the services they provide to be fully ready to use.  Stacks will be more readily available with all services starting after TVE shows a Running status after they’ve been paused and restarted at least one time.  Even after that initial pause/restart cycle, depending on what system you might jump into immediately after a stack transitions to Running in the TVE Web UI, you may encounter some service not fully ready, as there is a startup sequence for all of the virtual machines that does take some time.  It’s always best to wait an additional 10-15 minutes after a stack has transitioned to “Running” to ensure enough time has passed for all systems and services to be fully ready to operate.'
    },

    {
        question: 'My class is experiencing frequent disconnects as students are working in their stacks to complete Job and/or Problem Sheets.  What can be done to minimize or eliminate this?',
        answer: 'In nearly all cases, this is a symptom of local network performance and/or Internet connectivity issues.  If the issue is persistent across all stacks in a class, please contact the local support desk to have them investigate issues that may be occurring on the local network or with the Internet connection at the training site.  If the issue is only happening on one or two stacks in the class, the best course of action is to pause the affected stacks, and after they’ve reach the Paused status, start them back up.  If the issue is still present for more than 15-20 minutes after the stack has reached Running status, please contact the TVE Support team to get a ticket opened for further investigation.  If stack corruption has occurred, the only available remediation is to delete the faulty stack and create a new one.'
    }
];