import React from 'react';
import {
  Edit,
  SimpleForm,
  TextField,
  TextInput,
  SelectInput,
  required,
  maxLength,
  Toolbar,
  SaveButton,
  DeleteWithConfirmButton,
  useRecordContext,
  useGetList,
  Labeled,
  choices,
} from 'react-admin';
import BackButton from '../Components/BackButton';
import TveTitle from '../Components/TveTitle';
import { INSTRUCTOR_TRACKS } from '../Config/AuthProvider';
import { CircularProgress } from '@mui/material';

const CustomToolbar = () => {
  const record = useRecordContext();

  // get all classes to use to determine whether any are using this stack version
  const {
    data: tveClassAppUserData,
    isLoading,
    error,
  } = useGetList('tveclassappusers', {
    filter: { tvecanesversionid: record.id },
    pagination: { page: 1, perPage: 0 }
  });

  if (isLoading) {
    return <CircularProgress />;
  }
  if (error) {
    return <p>{error}</p>;
  }

  // Check if classData is not undefined and has data
  const countOfTveClassAppUsersUsingThisVersion =
    tveClassAppUserData && tveClassAppUserData.length > 0
      ? tveClassAppUserData.filter((obj) => obj.canesVersion.canesVersionId === record.id).length
      : 0;

  const deleteConfirmationMessageTitle = `Delete stack version: ` + record.name;

  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <SaveButton />
      <DeleteWithConfirmButton
        disabled={countOfTveClassAppUsersUsingThisVersion > 0}
        confirmTitle={deleteConfirmationMessageTitle}
        confirmContent="Are you sure you want to delete this stack version?"
        variant="contained"
        sx={{
          color: 'white',
          backgroundColor: 'red',
          padding: '6px 11px',
          '&:hover': {
            backgroundColor: '#0066CC',
          },
        }}
      />
    </Toolbar>
  );
};

const validateYesOrNo = choices(['Y', 'N'], 'Please choose one of the values');

const TveCanesVersionEdit = (props) => {
  return (
    <>
      <BackButton label="Return to Stack Version List" to="/canesversions" />
      <Edit title={<TveTitle pageName="Edit Stack Version" />} {...props}>
        <SimpleForm toolbar={<CustomToolbar />}>
          <Labeled label="Id">
            <TextField source="canesVersionId" />
          </Labeled>
          <TextInput label="Name" source="name" fullWidth validate={[required(), maxLength(255)]} />
          <TextInput source="description" fullWidth validate={maxLength(225)} />
          <SelectInput
            label="Track"
            source="track"
            choices={INSTRUCTOR_TRACKS}
            optionValue="name"
            validate={[required()]}
            disabled={true}
          />
          <TextInput
            label="Hardware Version"
            source="hardwareVersion"
            fullWidth
            validate={[required(), maxLength(255)]}
          />
          <TextInput
            label="Software Version"
            source="softwareVersion"
            fullWidth
            validate={[required(), maxLength(255)]}
          />
          <SelectInput
            label="Has Performance Test"
            source="hasPerformanceTest"
            choices={[
              { id: 'Y', name: 'Y' },
              { id: 'N', name: 'N' },
            ]}
            validate={[required(), validateYesOrNo()]}
            sx={{ width: 200 }}
          />
          <SelectInput
            label="Active"
            source="active"
            choices={[
              { id: 'Y', name: 'Y' },
              { id: 'N', name: 'N' },
            ]}
            validate={[required(), validateYesOrNo()]}
            sx={{ width: 200 }}
          />
        </SimpleForm>
      </Edit>
    </>
  );
};
export default TveCanesVersionEdit;
