import React from 'react';
import { Card, CardContent, Button, CardActions, CardHeader, Typography } from '@mui/material';
import NavigateIcon from '@mui/icons-material/Launch';
import { withLogging } from '../System/LoggingUtils';
import { withAuth } from '../Config/ApplicationDataProvider';
import axios from 'axios';
import { API_URL } from '../Config/ReactAppConfig';
import { CANES_TVE_ENV } from '../Config/ReactAppConfig';

const getGuacInfoUrl = (record) => `${API_URL}/tveclassappusers/${record.id}/guacamole`;
const getUserContentUrl = (record) => `${API_URL}/tveclassappusers/user-content/${record.id}`;

//get guacamole proxy url, set training status to in-progress.
//once that returns, log user into guacamole using the jwt as auth header to get past tomcat, and username / password to authenticate with guac.
//once that returns, set localstorage for the guac auth token object, and open new tab to the guac proxy.
const authenticateAndOpenGuacamole = (record) => {
  axios(
    withAuth({
      url: getGuacInfoUrl(record),
    })
  ).then(({ data }) => {
    const { guacProxyUrl } = data;

    axios(
      withAuth({
        url: `${guacProxyUrl}api/tokens`,
        method: 'post',
        data: `username=${record.guacUsername}&password=${record.guacPassword}`,
      })
    ).then(({ data }) => {
      localStorage.setItem('GUAC_AUTH', JSON.stringify(data));
      window.open(guacProxyUrl, '_blank');
    });
  });
};

const authenticateAndOpenResource = (record) => {
  axios(
    withAuth({
      url: getUserContentUrl(record),
    })
  ).then(({ data }) => window.open(`${data}/index.html`, '_blank'));
};

const GuacamoleInfo = ({ record }) => {
  const tveReady = record.tveStatus.name === 'Running';

  return (
    <div style={{ padding: 10 }}>
      <Card variant="outlined">
        <CardHeader
          title={
            <span>
              <Typography variant="dashboardCardLabel">Class Name</Typography>
              <Typography variant="dashboardCardText">{record.tveClass.name}</Typography>
              <br />
              <Typography variant="dashboardCardLabel">User</Typography>
              <Typography variant="dashboardCardText">
                {record.student?.appUser?.appUserAlias}
              </Typography>
            </span>
          }
        />

        <CardContent>
          {!tveReady && (
            <Typography>
              Your training environment is not ready - please wait for your instructor to enable
              your environment.
            </Typography>
          )}
        </CardContent>
        <CardActions>
          {tveReady && (
            <Button
              sx={{
                fontFamily: 'Segoe UI',
                fontSize: '14px',
                pb: 0,
                pt: 0,
                pl: 0,
                pr: 0,
                textTransform: 'none',
                justifyContent: 'center',
                width: '250px',
              }}
              variant="contained"
              onClick={() => authenticateAndOpenGuacamole(record)}
              color="primary"
            >
              <>
                <NavigateIcon />
                ENTER MY TVE TRAINING STACK
              </>
            </Button>
          )}
          {/* {CANES_TVE_ENV !== 'TEST' && ()} */}
          {/* don't show the View Additional TVE REsources if the class track is NSVT */}
          {record.tveClass.track !== 'NSVT' && (
            <Button
              color="primary"
              sx={{
                fontFamily: 'Segoe UI',
                fontSize: '14px',
                pb: 0,
                pt: 0,
                pl: 0,
                pr: 0,
                textTransform: 'none',
                justifyContent: 'center',
                width: '250px',
              }}
              variant="contained"
              onClick={() => authenticateAndOpenResource(record)}
            >
              <>
                <NavigateIcon />
                View Additional TVE Resources
              </>
            </Button>
          )}
        </CardActions>
      </Card>
    </div>
  );
};

export default withLogging(GuacamoleInfo);
